.carousel__content{
    width: 100%;
}

.mobile_menu_container.active {
    position: absolute !important;
    right: 7vw !important;
    top: 7vw !important;
}

.carousel__title--1 p{
    word-break: break-word
}

.swiper-slide{
    height: auto !important;
}


.swiper-button-prev{
    left: 0 !important;
}

.swiper-button-next{
    right: 0 !important;
}

image {
    image-rendering: auto;
}


@media only screen and (max-width: 768px) {

    .copyright .menu{
        display: block;
        margin-left: 0;
        padding-left: 0;
        border: none;
    }
}


